'use client';

import { usePreferences } from '@/components/providers/preferences-provider';
import { formatDistance } from '@/utils/number-format';
import { convertDistance } from '@turf/turf';

interface ElevationProps {
  elevation: number;
  originalUnit?: 'miles' | 'kilometers';
}

export function Elevation({
  elevation,
  originalUnit = 'miles',
}: ElevationProps) {
  const { unit } = usePreferences();
  const sign = elevation < 0 ? -1 : 1;
  const convertedElevation =
    convertDistance(
      Math.abs(elevation),
      originalUnit === 'miles' ? 'feet' : 'meters',
      unit === 'mile' ? 'feet' : 'meters',
    ) * sign;
  const unitNiceName = unit === 'mile' ? 'ft' : 'm';
  const formattedElevation = formatDistance(convertedElevation);
  return (
    <div className="tabular-nums inline-flex gap-1">
      <span>{formattedElevation}</span>
      <span>{unitNiceName}</span>
    </div>
  );
}
