'use client';
import { Elevation } from '@/components/typography/elevation';
import { trpc } from '@/utils/trpc';
import { Container } from '@streetferret/ui-web/container';
import { cn } from '@streetferret/utils';
import { point } from '@turf/turf';
import { type Position } from 'geojson';
import { useMemo } from 'react';
import { GeolocateControl } from 'react-map-gl';
import {
  Area,
  ComposedChart,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { TotalDuration } from '../_components/countdown-clock';
import { TransconMap } from '../_components/transcon-map';
import { FINISH_DATE, TRANSCON_DATE } from '../_lib/constants';

interface TransconTrackerProps {
  start: Position;
  end: Position;
  className?: string;
  showElevation?: boolean;
}
export function TransconTracker({
  start,
  end,
  className,
  showElevation,
}: TransconTrackerProps) {
  const trackingPoint = point([-73.98605298828235, 40.75740039060298]);

  return (
    <div>
      <div className={cn('h-[50svh]', className)}>
        <TransconMap
          latestPosition={trackingPoint as any}
          start={start}
          end={end}>
          <GeolocateControl
            style={{
              backgroundColor: 'rgb(var(--muted))',
            }}
            trackUserLocation
            showUserHeading
            onError={e => console.log(e)}
            positionOptions={{ enableHighAccuracy: true }}
            onOutOfMaxBounds={e => console.log('out of bounds', e)}
          />
        </TransconMap>
      </div>
      {showElevation ? <ElevationProfile /> : null}
      <Container>
        <div className="grid grid-cols-1 sm:grid-cols-2">
          <div className="flex items-center gap-1 text-xs py-1 h-6">
            <TotalDuration endDate={FINISH_DATE} startDate={TRANSCON_DATE} />
          </div>
        </div>
      </Container>
    </div>
  );
}

function ElevationProfile() {
  const elevationProfileQuery = trpc.paul.elevationProfile.useQuery(undefined, {
    refetchInterval: Infinity,
    refetchOnMount: false,
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
  const elevationProfile = elevationProfileQuery.data;
  const currentPositionIndex = elevationProfile?.length;
  const gradientOffset = useMemo(() => {
    return (currentPositionIndex ?? 0) / (elevationProfile?.length ?? 1);
  }, [elevationProfile, currentPositionIndex]);
  return (
    <Container className="h-40">
      <ResponsiveContainer width="100%">
        <ComposedChart
          data={elevationProfile ?? []}
          margin={{
            top: 5,
            right: 0,
            left: -30,
            bottom: 0,
          }}>
          <XAxis
            dataKey="i"
            tick={false}
            stroke="hsl(var(--secondary-foreground) / 0.5)"
          />
          <YAxis
            dataKey="v"
            width={80}
            fontSize={10}
            tickLine={true}
            stroke="hsl(var(--secondary-foreground) / 0.5)"
            tickFormatter={v =>
              `${Number(v).toLocaleString(undefined, {
                maximumFractionDigits: 0,
              })} ft`
            }
          />
          <Tooltip
            content={({ active, payload }) => {
              if (active && payload && payload.length) {
                const elevation = Number(payload[0].value);
                return (
                  <div className="bg-popover border p-2 rounded">
                    <Elevation elevation={elevation} />
                  </div>
                );
              }

              return null;
            }}
          />

          <defs>
            <linearGradient id="elevationFill" x1="0" y1="0" x2="1" y2="0">
              <stop
                offset={gradientOffset}
                stopColor="hsl(var(--primary))"
                stopOpacity={1}
              />
              <stop
                offset={gradientOffset}
                stopColor="hsl(var(--secondary))"
                stopOpacity={1}
              />
            </linearGradient>
            <linearGradient id="elevationStroke" x1="0" y1="0" x2="1" y2="0">
              <stop
                offset={gradientOffset}
                stopColor="hsl(var(--primary-foreground))"
                stopOpacity={1}
              />
              <stop
                offset={gradientOffset}
                stopColor="hsl(var(--secondary))"
                stopOpacity={1}
              />
            </linearGradient>
          </defs>
          <Area
            type="monotone"
            dataKey="v"
            stroke="url(#elevationStroke)"
            fill="url(#elevationFill)"
          />
          {currentPositionIndex ? (
            <ReferenceLine
              x={currentPositionIndex}
              stroke="hsl(var(--secondary-foreground) / 0.5)"
            />
          ) : null}
        </ComposedChart>
      </ResponsiveContainer>
    </Container>
  );
}
